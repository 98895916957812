import { useState } from 'react';
import classNames from 'classnames';

import { CfFurnitureFeature } from '@mycs/contentful';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Button from 'mycs/shared/components/Button/Button';
import ContactForm from 'mycs/shared/components/ContactForm/ContactForm';
import HelpStore from 'mycs/shared/stores/HelpStore/HelpStore';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Icon from 'mycs/shared/components/Icon/Icon';
import Localize from 'mycs/shared/components/Localize/Localize';
import ProductThumbnails, {
  Thumbnail,
} from 'mycs/shared/components/FurnitureFeatureListGrid/ProductThumbnails';
import SafeText from 'mycs/shared/components/SafeText/SafeText';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';

import styles from './ProductFeature.scss';

interface Props extends Partial<CfFurnitureFeature> {
  onClose: () => void;
  isMobile: boolean;
}

function ProductFeature({
  title,
  description,
  thumbnails = [],
  thumbnailIcons = [],
  onClose,
  picture,
  button,
  simpleButton,
  widePicture,
  mobileVideo,
  isMobile,
  video,
  helpButton,
}: Props) {
  const { locale } = useLocale();
  const getDefaultVideo = () => {
    if (isMobile && mobileVideo) return mobileVideo;
    return video;
  };

  const getDefaultImage = () => {
    if (widePicture) return widePicture;
    return thumbnails[0];
  };

  const [activeImage, setActiveImage] = useState<Thumbnail>(
    getDefaultImage() as Thumbnail
  );

  const [showContactForm, setShowContactForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const withCarousel = thumbnails.length > 4;
  const image = activeImage || picture || thumbnails[0];

  const toggleHelp = () => {
    HelpStore.toggle();
    HelpStore.show();
    onClose();
  };

  const renderOrderRequestForm = () => {
    const placeholderText = I18nUtils.localize(
      locale,
      'How many %s would you like? What sizes and materials…'
    );
    const placeholder = title ? placeholderText.replace('%s', title) : '';
    return (
      <div className={styles.formContainer}>
        <ContactForm
          isOpen={showContactForm}
          isModal={false}
          requestClose={() => setShowContactForm(false)}
          modalHeader={title}
          onFormSubmit={() => setFormSubmitted(true)}
          formType="preSales"
          formProps={{
            message: {
              nativeProps: { placeholder },
              label: 'What would you like to order?',
            },
          }}
          submitText="Send order request"
          successMessage={
            <span>
              <h5>
                <Localize>Thank you for your request!</Localize>
              </h5>
              <p>
                <Localize>
                  We will get back to you soon to fulfill your order.
                </Localize>
              </p>
            </span>
          }
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {/* Close icon */}
      <div className={styles.closeIconContainer}>
        <Icon
          onClick={onClose}
          iconContainerClass={classNames(
            styles.close,
            styles.title,
            'clickable',
            'hidden-print',
            {
              [styles.blackColor]: formSubmitted,
            }
          )}
          iconName="general/close.svg"
        />
      </div>

      {!formSubmitted && (
        <div
          style={
            !video
              ? {
                  backgroundImage: image
                    ? `url(${UrlProviderService.getImageUrl(
                        image.url,
                        image.width,
                        image.height
                      )})`
                    : '',
                }
              : {}
          }
          className={classNames(styles.mediaContainer)}
        >
          {video && (
            <video
              className={styles.video}
              src={getDefaultVideo()?.url}
              autoPlay
              muted
              controls
              playsInline
              loop
            />
          )}

          {/* Thumbnails - shown on desktop*/}
          <div
            className={classNames(styles.thumbnailsContainer, {
              [styles.videoOverlay]: Boolean(video),
              [styles.withCarousel]: withCarousel,
            })}
          >
            <ProductThumbnails
              setActiveImage={setActiveImage}
              activeImage={activeImage}
              productThumbnails={thumbnails}
              thumbnailIcons={thumbnailIcons}
            />
          </div>
          {/* Carousel thumbnails - shown on tablet */}
          {withCarousel && (
            <div
              className={classNames({
                [styles.carouselContainer]: withCarousel,
              })}
            >
              <ProductThumbnails
                setActiveImage={setActiveImage}
                activeImage={activeImage}
                productThumbnails={thumbnails}
                thumbnailIcons={thumbnailIcons}
                withCarousel={withCarousel}
              />
            </div>
          )}
        </div>
      )}
      <div
        className={classNames(styles.contentContainer, {
          [styles.noPaddingBottom]: showContactForm,
        })}
      >
        {!formSubmitted && (
          <>
            {/* Carousel thumbnails - shown on mobile */}
            <div
              className={classNames({
                [styles.carouselContainer]: withCarousel,
                [styles.mobileThumbsnailContainer]: !withCarousel,
              })}
            >
              <ProductThumbnails
                setActiveImage={setActiveImage}
                activeImage={activeImage}
                productThumbnails={thumbnails}
                thumbnailIcons={thumbnailIcons}
                withCarousel={withCarousel}
              />
            </div>
            {/* Title */}
            <p className={styles.title}>{title}</p>
            {/* Form */}
          </>
        )}
        {showContactForm && renderOrderRequestForm()}
        {/* Show container if contact form is hidden */}
        {!showContactForm && (
          <>
            {/* Description */}
            <div className={styles.description}>
              <SafeText content={description} />
            </div>

            {/* Button container */}

            {(button || simpleButton || helpButton) && (
              <div className={styles.buttonContainer}>
                {button && (button.contactCustomerService || button.url) && (
                  <Button
                    className={styles.button}
                    href={button.contactCustomerService ? '' : button.url}
                    onClick={() => {
                      button.contactCustomerService
                        ? setShowContactForm(true)
                        : null;
                      onClose();
                    }}
                    text={button.text}
                    isPrimaryCtaFallback
                  />
                )}

                {simpleButton && (
                  <Button
                    className={styles.button}
                    href={simpleButton.url}
                    text={simpleButton.text}
                    isPrimaryCtaFallback
                  />
                )}
                {helpButton && (
                  <Button
                    className={styles.button}
                    onClick={() => toggleHelp()}
                    text={I18nUtils.localize(locale, 'Contact us')}
                    isPrimaryCtaFallback
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ProductFeature;
