import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';

import { CfAsset } from '@mycs/contentful';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';

import styles from './ProductThumbnails.scss';

interface Props {
  productThumbnails: CfAsset[];
  thumbnailIcons: CfAsset[];
  activeImage?: CfAsset;
  setActiveImage: (image: Thumbnail) => void;
  withCarousel?: boolean;
}

export interface Thumbnail extends CfAsset {
  icon?: string;
}

export default function ThumbnailCarousel({
  productThumbnails,
  thumbnailIcons,
  setActiveImage,
  activeImage,
  withCarousel,
}: Props) {
  const [thumbnails, setThumbnails] = useState<Thumbnail[]>([]);

  const carouselSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    variableWidth: true,
    className: styles.slides,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setThumbnails(mergeThumbnailsWithIcons(productThumbnails, thumbnailIcons));
  }, [productThumbnails, thumbnailIcons]);

  const mergeThumbnailsWithIcons = (
    thumbnails: CfAsset[],
    icons: CfAsset[]
  ) => {
    const thumbnailsWithIcon: Thumbnail[] = thumbnails.map((thumbnail, i) => {
      let icon = thumbnail.url;
      if (icons.length > i && icons[i].url) icon = icons[i].url;
      return { ...thumbnail, icon: icon };
    });
    return thumbnailsWithIcon;
  };

  const renderNumberedThumb = (index: number) => {
    return (
      <div className={classNames(styles.numberedThumb, styles.thumbnail)}>
        <span>{index++}</span>
      </div>
    );
  };

  const renderThumbnails = () => {
    return thumbnails.map((thumb, index) => {
      return (
        <div
          onMouseOver={() => {
            setActiveImage(thumb);
          }}
          key={`${thumb._id}`}
        >
          <div
            className={classNames(
              styles.carouselThumbnailContainer,
              styles.thumbnailContainer
            )}
          >
            {!thumb.icon ? (
              renderNumberedThumb(index + 1)
            ) : (
              <div
                className={classNames(styles.thumbnail)}
                style={{
                  backgroundImage: thumb.icon
                    ? `url(${UrlProviderService.getImageUrl(thumb.icon)})`
                    : '',
                }}
              ></div>
            )}
            <div
              className={classNames(styles.thumbnail, {
                [styles.imageOverlay]: activeImage?._id === thumb._id,
              })}
            ></div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {withCarousel ? (
        <Slider {...carouselSettings}> {renderThumbnails()}</Slider>
      ) : (
        renderThumbnails()
      )}
    </>
  );
}
