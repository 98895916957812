import { CfMaterial } from '@mycs/contentful';
import useSWR from 'swr';

const maxItemsInSampleBox = 8;
const key = 'sample-box:v1';

export function useSampleBox() {
  const { data: sampleBoxItems = [], mutate } = useSWR(key, () => {
    // TODO The box SKUs should probably be
    // in a server-side session storage instead.
    const storageItem = localStorage.getItem(key);
    if (storageItem) {
      return JSON.parse(storageItem) as CfMaterial[];
    }

    return [];
  });

  function setSampleBoxItems(items: CfMaterial[]) {
    localStorage.setItem(key, JSON.stringify(items));
    mutate();
  }

  return {
    sampleBoxItems,
    maxItemsInSampleBox,
    setSampleBoxItems,
  };
}
