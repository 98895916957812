import { CfFurnitureFeatureList } from '@mycs/contentful';
import FurnitureFeatureListGrid from 'mycs/shared/components/FurnitureFeatureListGrid/FurnitureFeatureListGrid';
interface Props {
  content: CfFurnitureFeatureList;
  variantFeatures?: Partial<CfFurnitureFeatureList>;
  onClose?: () => void;
  selected?: number;
  furnitureType?: string;
  subtype?: string;
  noBackground?: boolean;
  containerClassName?: string;
  headingClassName?: string;
  activeIndex?: number;
  onActiveIndexUpdate?: (activeFeatureIndex: number) => void;
  titleClassName?: string;
  selectedFeature?: number | null;
  id?: string;
}

export default function ProductFeatures({ content: features, id }: Props) {
  return (
    <div className="ProductFeatures__featuresContainer" id={id}>
      <FurnitureFeatureListGrid featureList={features} />
    </div>
  );
}
