import { useState } from 'react';

import { CfFurnitureFeature, CfFurnitureFeatureList } from '@mycs/contentful';
import { useDevice } from 'mycs/router/DeviceContext';
import ActiveProductFeature from 'mycs/shared/components/FurnitureFeatureListGrid/ProductFeature';
import Modal from 'mycs/shared/components/Modal/Modal';
import FurnitureFeatureTile from 'mycs/shared/components/FurnitureFeatureListGrid/FurnitureFeatureTile';
import Video from 'mycs/shared/components/Video/Video';

import styles from './FurnitureFeatureListGrid.scss';

interface Props {
  featureList: Partial<CfFurnitureFeatureList>;
}

export default function FurnitureFeatureListGrid({
  featureList: {
    title,
    additionalOptionsTitle,
    video,
    mobileVideo,
    features,
    additionalOptions,
  },
}: Props) {
  const device = useDevice();
  const [activeFeature, setActiveFeature] =
    useState<CfFurnitureFeature | null>();
  const isMobile = device.isMobile || device.hasPhoneDimensions;

  const onClose = () => {
    setActiveFeature(null);
  };

  const getDefaultvideo = () => {
    if (isMobile && mobileVideo) return mobileVideo;
    return video;
  };

  const renderActiveFeature = (feature: CfFurnitureFeature) => {
    return (
      <div className={'activeFeature'}>
        <ActiveProductFeature
          isMobile={isMobile}
          thumbnailIcons={feature.thumbnailIcons}
          onClose={onClose}
          button={feature.button}
          simpleButton={feature.simpleButton}
          {...feature}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.allFeaturesContainer}>
        <Modal
          headerClass={styles.modalHeader}
          contentClass={styles.modalContent}
          overlayClass={styles.modalOverlay}
          isOpen={Boolean(activeFeature)}
          onClose={() => setActiveFeature(null)}
        >
          {activeFeature && renderActiveFeature(activeFeature)}
        </Modal>
        <h1 className={styles.header}>{title}</h1>

        {video && (
          <div className={styles.videoContainer}>
            <Video
              video={getDefaultvideo()?.video.url}
              className={styles.video}
              videoPoster={getDefaultvideo()?.videoPoster?.url}
            />
          </div>
        )}
        <div className={styles.tilesContainer}>
          {features && (
            <div className={styles.featuresContainer}>
              {features.map((feature) => (
                <div key={feature._id} className={styles.tileContainer}>
                  <FurnitureFeatureTile
                    feature={feature}
                    tileType="feature"
                    isActive={activeFeature?._id === feature._id}
                    onSelect={setActiveFeature}
                    isMobile={isMobile}
                  />
                </div>
              ))}
            </div>
          )}
          {additionalOptions && (
            <div id="features-details-options-anchor">
              <p className={styles.additionalOptionsTitle}>
                {additionalOptionsTitle}
              </p>

              <div className={styles.featuresContainer}>
                {additionalOptions.map((option: CfFurnitureFeature) => (
                  <div
                    key={option._id}
                    className={styles.additionalTileContainer}
                  >
                    <FurnitureFeatureTile
                      key={option._id}
                      feature={option}
                      isActive={activeFeature?._id === option._id}
                      tileType="additional"
                      onSelect={setActiveFeature}
                      isMobile={isMobile}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
