import invert from 'lodash/invert';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

import { useCart } from 'mycs/hooks/useCart';
import { useContentfulContext } from 'mycs/shared/services/ContentfulService/ContentfulContext';

export interface VolumeDiscount {
  next: null | {
    // Next cart total target.
    target: number;
    // Next volume discount percentage.
    percentage: number;
  };
  // Current volume discount percentage.
  currPercentage: number;
}

export function useVolumeDiscount() {
  const contentful = useContentfulContext();
  const { cart } = useCart();

  const volumeDiscount = useMemo<null | VolumeDiscount>(() => {
    const { quantityVolumeDiscounts } = contentful;
    if (!quantityVolumeDiscounts.length) {
      return null;
    }

    // Find Volume Discount in the Volume-Quantity discount set.
    const volumeDiscountCF = quantityVolumeDiscounts.find((discount) =>
      discount.discountType?.some((type) => type === 'volume')
    );
    if (!volumeDiscountCF) {
      return null;
    }

    const { discounts: volumeDiscountMap } = volumeDiscountCF;
    if (!volumeDiscountMap || isEmpty(volumeDiscountMap)) {
      return null;
    }

    // Map the Volume Discount map values (discount percentage)
    // and sort the values in ascending order.
    const discounts = Object.values(volumeDiscountMap).sort((a, b) => a - b);
    const discountMapInverted = invert(volumeDiscountMap);
    const volumeDiscount: VolumeDiscount = {
      currPercentage: cart?.lineItemDiscount || 0,
      next: null,
    };

    // Determine the next discount.
    for (const percentage of discounts) {
      if (percentage > volumeDiscount.currPercentage) {
        volumeDiscount.next = {
          target: parseInt(discountMapInverted[percentage]),
          percentage,
        };
        break;
      }
    }

    return volumeDiscount;
  }, [contentful, cart]);

  return volumeDiscount;
}
