import { useRef, useState } from 'react';
import classNames from 'classnames';

import { CfAsset, CfFurnitureFeature } from '@mycs/contentful';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useTrackEvent } from '../TrackEvent/useTrackEvent';
import Button from 'mycs/shared/components/Button/Button';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';

import chevronDownSvg from 'mycs-img/icons/general/arrow-chevron-down.svg';
import styles from './FurnitureFeatureTile.scss';
import Alink from '../Alink/Alink';

type TileTypes = 'feature' | 'additional';

type Props = {
  feature: Partial<CfFurnitureFeature>;
  tileType: TileTypes;
  isActive: boolean;
  onSelect?: Function;
  isMobile: boolean;
};

export default function FurnitureFeatureTile({
  feature,
  tileType,
  onSelect,
  isActive,
  isMobile,
}: Props) {
  const {
    filler,
    _id,
    picture,
    mobilePicture,
    title,
    link,
    description,
    video,
    mobileVideo,
  } = feature;
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
  const { locale } = useLocale();
  const videoRef = useRef<HTMLVideoElement>(null);
  const trackEvent = useTrackEvent();

  const renderVideoTile = (vid: CfAsset, mobileVid?: CfAsset) => {
    return (
      <div
        className={classNames(styles.videoContainer, {
          [styles.show]: videoPlaying,
        })}
      >
        <video
          ref={videoRef}
          className={styles.video}
          muted
          autoPlay={false}
          src={(isMobile && mobileVid ? mobileVid : vid).url}
        />
      </div>
    );
  };

  const playVideo = () => {
    if (videoRef.current) {
      setVideoPlaying(true);
      const video = videoRef.current;
      video.play();
    }
  };

  const stopVideo = () => {
    if (videoRef.current) {
      setVideoPlaying(false);
      const video = videoRef.current;
      video.currentTime = 0;
      video.pause();
    }
  };

  const bgImage = (desktopPic?: CfAsset, mobilePic?: CfAsset): string => {
    const pic = isMobile && mobilePic ? mobilePic : desktopPic;
    return pic
      ? `url(${UrlProviderService.getImageUrl(pic.url, pic.width, pic.height)})`
      : '';
  };

  return (
    <>
      {tileType === 'feature' && !filler && (
        <div
          style={{ backgroundImage: bgImage(picture, mobilePicture) }}
          key={_id}
          className={classNames(styles.tile, {
            [styles.videoTileContainer]: Boolean(video),
          })}
          id={_id}
          onClick={() => (onSelect ? onSelect(feature) : {})}
          onMouseEnter={() => playVideo()}
          onMouseLeave={() => stopVideo()}
        >
          {video && renderVideoTile(video, mobileVideo)}

          <div className={styles.grow} onClick={trackEvent.wrapOnClick()} />

          <div className={styles.titleContainer}>
            <p className={styles.title} onClick={trackEvent.wrapOnClick()}>
              {title}
            </p>

            <div className={styles.buttonContainer}>
              <Button
                iconContent={chevronDownSvg}
                iconClassName={styles.additionalTileBbuttonIcon}
                isActive={isActive}
                isOvalBlackWhiteCta
                text={I18nUtils.localize(locale, 'More options')}
                onClick={() => (onSelect ? onSelect(feature) : null)}
              />
              <div
                className={styles.grow}
                onClick={trackEvent.wrapOnClick()}
              ></div>
            </div>
          </div>
        </div>
      )}

      {filler && (
        <div key={_id} className={classNames(styles.tile, styles.fillerTile)}>
          <div className={styles.contentContainer}>
            <div className={styles.descriptionContainer}>
              <p className={styles.description}>{description}</p>
            </div>
            {link && (
              <Alink href={link.url} className={styles.link}>
                {link.text}
              </Alink>
            )}
          </div>
        </div>
      )}

      {tileType === 'additional' && (
        <div
          style={{ backgroundImage: bgImage(picture, mobilePicture) }}
          className={classNames(styles.tile, styles.additionalTile)}
          onClick={() => (onSelect ? onSelect(feature) : {})}
        >
          <div className={styles.grow} onClick={trackEvent.wrapOnClick()} />

          <div className={styles.titleContainer}>
            <p onClick={trackEvent.wrapOnClick()}>{title}</p>

            <Button
              className={classNames(
                styles.additionalTileButton,
                styles.button,
                { [styles.active]: isActive }
              )}
              onClick={() => (onSelect ? onSelect(feature) : {})}
              iconContent={chevronDownSvg}
              iconClassName={styles.additionalTileButtonIcon}
            />
          </div>
        </div>
      )}
    </>
  );
}
