import { CfQuantityDiscountsExplainer } from '@mycs/contentful';
import { useDevice } from 'mycs/router/DeviceContext';
import { useVolumeDiscount } from 'mycs/hooks/useVolumeDiscount';
import CfImage from 'mycs/shared/components/CfImage/CfImage';

import styles from './DiscountExplainer.scss';

export default function DiscountExplainer({
  content: {
    picture,
    title,
    description,
    anchor,
    quantityDiscounts,
    furnitureType,
    furnitureIcons,
    furnitureStackIcon,
    quantityLabel,
  },
}: {
  content: CfQuantityDiscountsExplainer;
}) {
  const volumeDiscountState = useVolumeDiscount();
  const { isTablet, hasPhoneDimensions } = useDevice();

  if (volumeDiscountState && !quantityDiscounts) return null;

  return (
    <div className={styles.container} id={anchor}>
      <div className="contentContainer">
        <div className={styles.wrapper}>
          <div className={styles.card}>
            <div className={styles.text}>
              <h5>{title}</h5>

              <p>{description}</p>
            </div>
            <div className={styles.imageWrapper}>{renderImage()}</div>
          </div>

          <div className={styles.discounts}>{renderDiscounts()}</div>
        </div>
      </div>
    </div>
  );

  function renderIcons(quantity: number): React.ReactNode {
    const maxSingleIcons = 6;
    const piecesInStack = 3;

    const stacksCount =
      furnitureStackIcon && quantity > maxSingleIcons
        ? Math.ceil((quantity - maxSingleIcons) / piecesInStack)
        : 0;

    const doubleIconsCount = furnitureType === 'cushion' ? 2 : 1;

    const iconsCount =
      quantity / doubleIconsCount - stacksCount * piecesInStack;

    const icons = [];

    for (let i = 0; i < stacksCount; i++) {
      icons.push(furnitureStackIcon);
    }

    if (furnitureIcons) {
      let count = furnitureIcons.length;
      for (let i = 0; i < iconsCount; i++) {
        icons.push(furnitureIcons[count % furnitureIcons.length]);
        count += 1;
      }
    }

    return icons.map(
      (icon, index) =>
        icon && <CfImage key={`${icon._id}-${index}`} asset={icon} />
    );
  }

  function renderDiscounts(): React.ReactNode {
    const discounts = quantityDiscounts?.discounts ?? {};

    return Object.keys(discounts)
      .map(Number)
      .sort((a, b) => a - b)
      .map((quantity: number) => {
        const percentage = discounts[quantity];

        return (
          <div key={quantity} className={styles.discountItem}>
            <div className={styles.discountConditions}>
              <div className={styles.discountPercentage}>–{percentage}%</div>

              <div className={styles.discountQuantity}>
                {quantityLabel?.replace(/%d/g, quantity.toString())}
              </div>
            </div>

            <div className={styles.discountIcons}>{renderIcons(quantity)}</div>
          </div>
        );
      });
  }

  function renderImage(): React.ReactNode {
    // FIXME: could be done through CSS just by hidding the image
    if (!picture || hasPhoneDimensions || isTablet) return null;

    const imageSizes = '(min-width: 1024px) 50vw';

    return (
      <CfImage
        className={styles.background}
        asset={picture}
        sizes={imageSizes}
      />
    );
  }
}
